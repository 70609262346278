<template>
  <v-container class="register pa-0" fluid>
    <v-container v-if="!completed" class="main-register-panel" fill-height fluid>
      <v-img @click="navigate('/')" class="disrupt-logo" :src="'./icons/logo-d+.png'" :height="32" :max-width="200" contain />
      <v-row class="mt-4" align="center" justify="center">
        <v-col cols="12" md="4">
          <user ref="user" :userData="userData" v-show="sequence == 0" @next="next" @back="back"/>
          <plans :plan="plan" v-show="sequence == 1" @next="next" @back="back"/>
          <payment :payment="payment" :plan="plan" v-show="sequence == 2 && !isCoupon" @next="next" @back="back" :preSubscribeMethod="register" @finish="completeRegistration"/>
          <coupon :plan="plan" v-if="sequence == 2 && isCoupon" @back="back"  :preSubscribeMethod="register" @finish="completeRegistration"/>
          <paypal v-if="sequence == 3 && payment.selectedPayment.key == 'paypal'" :plan="plan" @next="next" @back="back" :preSubscribeMethod="register" @finish="completeRegistration"/>
          <stripe v-if="sequence == 3 && payment.selectedPayment.key == 'stripe'" :plan="plan" @next="next" @back="back" :preSubscribeMethod="register" @finish="completeRegistration"/>
          <free v-if="sequence == 3 && payment.selectedPayment.key == 'free'" :plan="plan" @next="next" @back="back" :preSubscribeMethod="register" @finish="completeRegistration"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else class="finished-register-panel" fill-height fluid>
      <v-img @click="navigate('/')" class="disrupt-logo" :src="'./icons/logo-d+.png'" :height="32" :max-width="200" contain />
      <v-row align="center" justify="center">
        <v-col cols="12" md="4" class="text-center">
          <div class="thank-you-text">Thank you!</div>
          <div class="thank-you-subtext">Traveling to <span class="primary--text">new content...</span></div>
          <v-progress-circular
            class="ma-6"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-toolbar dark flat color="rgba(0,0,0,0)" width="100%" height="80" class="register-footer">
      <v-icon v-if="!completed" x-large>mdi-lock</v-icon>
      <v-icon v-else x-large>mdi-lock-open-variant</v-icon>
      <v-spacer/>
      <div class="steps" v-if="!completed">
        <v-flex class="text-center">
          Step {{sequence+1}}
        </v-flex>
        <v-flex>
          <v-icon :color="sequence == 0 ? 'primary': 'none'">mdi-checkbox-blank-circle</v-icon>
          <v-icon :color="sequence == 1 ? 'primary': 'none'">mdi-checkbox-blank-circle</v-icon>
          <v-icon :color="sequence == 2 ? 'primary': 'none'">mdi-checkbox-blank-circle</v-icon>
        </v-flex>
      </div>
    </v-toolbar>
  </v-container>
</template>
<script>
import { Api } from '../js/api';

import user from '../components/register/user'
import plans from '../components/register/plans'
import payment from '../components/register/payment'

import paypal from '../components/register/paypal'
import stripe from '../components/register/stripe'
import coupon  from '../components/register/coupon'
import free  from '../components/register/free'

export default {
  name: 'Register',
  components: {
    user,
    plans,
    payment,
    paypal,
    stripe,
    coupon,
    free
  },
  data: () => ({
    sequence: 0,
    completed: false,
    confirmed: false,
    userData: {
      username: '',
      password: '',
      email: ''
    },
    plan: {
      selectedPlan: null
    },
    payment: {
      selectedPayment: { key: 'stripe'}
    },
    paymentDetails: {
      planType: null,
      paymentType: null
    }
  }),
  computed: {
    canNext () {
      if (this.sequence == 0) {
        if (this.userData.username != '' && this.userData.password != '' && this.userData.email != '') {
          return this.$refs.user.isUsernameValid && this.$refs.user.isPasswordValid && this.$refs.user.isEmailValid;
        }
      } else if (this.sequence == 1) {
        if (this.plan.selectedPlan != null) {
          return true;
        }
      } else if (this.sequence == 2) {
        if (this.payment.selectedPayment != null) {
          return true;
        }
      }
      return false;
    },
    nextText () {
      if (this.sequence == 2 || this.sequence == 3) {
        return 'Pay ' + this.plan.selectedPlan.currency + this.plan.selectedPlan.price;
      }
      return 'Next';
    },
    nextColor () {
      if (this.sequence == 2) {
        return 'primary';
      }
      return 'inherit';
    },
    isCoupon () {
      if (this.plan.selectedPlan) {
        return this.plan.selectedPlan.title == 'Coupon'
      }
      return false;
    },
    currentUser () {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    navigate: function (path) {
      this.$router.push(path).catch(()=>{});
    },
    back: function () {
      if (this.sequence > 0) {
        this.sequence--;
      }
    },
    next: function () {
      if (this.sequence < 3) {
        this.sequence++;
      }
    },
    register: function () {
      return new Promise((resolve) => {
        let data = this.userData;
        data.captcha = this.$store.getters.getCaptchaResponse;

        Api.post('Auth/register', data).then(function (res) {
          resolve(res.valid);
        })
      })
    },
    completeRegistration: function () {
      let self = this;

      self.completed = true;
      setTimeout(() => {
        self.$store.dispatch('getUser');
        self.$store.commit('setIsLoggedIn', true);
        self.navigate('/');
      }, 3000);
    }
  },
  mounted () {
    if (this.currentUser) {
      // already registered
      this.navigate('/')
    }
  }
}
</script>
<style lang="scss">
.register {
  background-image: url('/backgrounds/register-optimized.png');
  height: 100vh;
  background-size: cover;
  background-position: left;
  background-blend-mode: multiply;
  background-color: #848484;
}
.register-footer {
  backdrop-filter: blur(4px);
}
.main-register-panel {
  background-color: #0f0f0f;
  height: calc(100vh - 80px);
  overflow-y: auto;
}
.finished-register-panel {
  height: calc(100vh - 80px);
}
.disrupt-logo {
  // position: absolute;
  top: 16px;
  left: 16px;
}
.register-title {
  font-size: 32px;
  justify-content: center;
}
.next-button .v-btn__content {
  font-size: 28px !important;
  text-transform: none;
  letter-spacing: 0px !important;
}
.thank-you-text {
  font-size: 80px;
}
.thank-you-subtext{
  font-size: 25px;
  letter-spacing: 0px;
}
</style>