<template>
  <v-card flat color="#00000000">
    <v-container class="free-body">
      <v-row align="center" class="text-center">
        <v-col>
          <div class="free-body-title">
            One last thing...
          </div>
          <div class="pa-2">
            <captcha />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="64" :disabled="disabled" @click="back()">
        <v-icon :size="48">mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile depressed :height="64" class="next-button" color="inherit" :disabled="disabled || !canNext" :loading="loading" @click="next()">
        Finish
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>

import captcha from './captcha.vue'

export default {
  name: 'Coupon',
  props: ['plan', 'preSubscribeMethod'],
  components: {
    captcha
  },
  data: () => ({
    disabled: false,
    loading: false,
  }),
  computed: {
    captchaVerified () {
      return this.$store.getters.getCaptchaResponse != null;
    },
    canNext () {
      return this.captchaVerified;
    }
  },
  watch: {
  },
  methods: {
    next: function () {
      let scope = this;

      scope.preSubscribeMethod().then(function () {
        scope.$emit('finish');
      })
    },
    back: function () {
      this.$emit('back');
    },
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.coupon-body {
  height: 200px;
}
.coupon-body-title {
  font-size: 32px;
}
.coupon-body-text {
  font-size: 24px;
}
</style>
