<template>
  <v-card flat color="#00000000">
    <v-card-title class="register-title text-center">
      Welcome,<br> please choose a username
    </v-card-title>
    <v-card-text class="user-card-text">
      <serverValidationField ref="usernameField" v-model="userData.username" label="Username" fieldClass="square-fields" :height="fieldHeight" @keydown="usernameKeydown"
        endpoint="Auth/usernameAvailable" :dataFunction="usernameDataFunction" :responseFunction="usernameResponseFunction" :messages="usernameFailReason" />
      <serverValidationField ref="passwordField" v-model="userData.password" label="Password" fieldClass="square-fields"  :height="fieldHeight" @keydown="passwordKeydown"
        :resolveFunction="validatePassword" :messages="passwordFailReason" :inputDelay="10"/>
      <serverValidationField ref="emailField" v-model="userData.email" label="Email" fieldClass="square-fields" :height="fieldHeight" @keydown="emailKeydown"
        endpoint="Auth/emailAvailable" :dataFunction="emailDataFunction" :responseFunction="emailResponseFunction" :messages="emailFailReason" :inputDelay="10"/>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn tile depressed :height="64" :width="128" class="next-button" color="inherit" :disabled="!canNext" @click="next()">
        Next
      </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>
<script>
import serverValidationField from '../global/serverValidationField'

export default {
  name: 'User',
  components: {
    serverValidationField
  },
  props: ['userData'],
  data: () => ({
    usernameFailReason: null,
    passwordFailReason: null,
    emailFailReason: null,
  }),
  computed: {
    fieldHeight () {
      if (this.mobile) {
        return 60;
      }
      return 80;
    },
    mobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    isUsernameValid () {
      if (this.$refs.usernameField) {
        return this.$refs.usernameField.isValueValid;
      }
      return false;
    },
    isPasswordValid () {
      if (this.$refs.passwordField) {
        return this.$refs.passwordField.isValueValid;
      }
      return false;
    },
    isEmailValid () {
      if (this.$refs.emailField) {
        return this.$refs.emailField.isValueValid;
      }
      return false;
    },
    canNext () {
      if (this.userData.username != '' && this.userData.password != '' && this.userData.email != '') {
        return this.isUsernameValid && this.isPasswordValid && this.isEmailValid;
      }
      return false;
    },
  },
  watch: {
  },
  methods: {
    usernameKeydown: function (e) {
      if (e.key == 'Enter') {
        this.$refs.passwordField.$el.getElementsByTagName('input')[0].focus();
      }
    },
    passwordKeydown: function (e) {
      if (e.key == 'Enter') {
        this.$refs.emailField.$el.getElementsByTagName('input')[0].focus();
      }
    },
    emailKeydown: function (e) {
      if (e.key == 'Enter') {
        if (this.canNext) {
          this.next();
        }
      }
    },
    next: function () {
      this.$emit('next');
    },
    back: function () {
      this.$emit('back');
    },
    usernameDataFunction: function (username) {
      let data = {
        username: username
      }
      return data;
    },
    emailDataFunction: function(email) {
      let data = {
        email: email
      }
      return data;
    },
    usernameResponseFunction: function (res) {
      if (res.data === true) {
        this.usernameFailReason = '';
        return true;
      } else {
        this.usernameFailReason = 'Username is taken';
        return false;
      }
    },
    emailResponseFunction: function (res) {
      if (res.data === true) {
        this.emailFailReason = '';
        return true;
      } else {
        this.emailFailReason = 'Email is already in use or in the wrong format';
        return false;
      }
    },
    validatePassword: function (pass) {
      return new Promise((resolve) => {
        // length
        if (pass.length < 9) {
          this.passwordFailReason = 'Must be longer than 8 characters'
          resolve(false);
          return;
        }
        this.passwordFailReason = null;
        resolve(true);
      })
    },
    validateEmail: function (email) {
      return new Promise((resolve) => {
        const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.toLowerCase().match(validation)) {
          this.emailFailReason = 'Incorrect email format';
          resolve(false);
          return;
        }

        this.emailFailReason = null;
        resolve(true);
      })
    },
  },
  mounted () {
  }
}
</script>
<style lang="scss">
.user-card-text {
  // max-height: calc(100vh - 300px);
}
</style>